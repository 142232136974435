import { ExternalLinkIcon } from 'lucide-react';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

type ExternalLinkProps = {
  href: AnchorHTMLAttributes<HTMLAnchorElement>['href'];
};

export function ExternalLink({ href, children }: PropsWithChildren<ExternalLinkProps>) {
  return (
    <a
      className="inline-flex items-center text-primary underline hover:text-primary-dark hover:no-underline"
      target="_blank"
      rel="noreferrer noopener"
      href={href}
    >
      {children}
      <ExternalLinkIcon className="ml-1 h-3 w-3" />
    </a>
  );
}
