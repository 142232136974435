import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser';

import { Link } from '@remix-run/react';
import parse, { attributesToProps, domToReact, Element } from 'html-react-parser';
import { UserRoundIcon } from 'lucide-react';
import React from 'react';
import { Trans } from 'react-i18next';

import { ExternalLink } from '~/components/ExternalLink';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import { Avatar, AvatarFallback, AvatarImage } from '~/components/ui/avatar';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '~/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from '~/components/ui/table';

// Comment: type (comment)
// Element: name, attribs, type (tag, script, style)
// Node: parent, prev, next, startIndex, endIndex, type (root, text, directive, comment, script, style, tag, cdata, doctype)

// const Tag = ({tagName, ...children}: {tagName: string, children: ReactNode}) => {
//   return React.createElement(tagName, null, children);
// }

const randomString = (length: number = 6) => {
  return Math.random().toString(20).substring(2, length);
};

export const parseHtml = (html: string) => {
  const options: HTMLReactParserOptions = {
    trim: true,
    replace: (domNode) => {
      const isElement = domNode instanceof Element;

      if (!isElement) {
        return;
      }

      const children = domNode.children as DOMNode[];
      const nodeId = randomString();

      if (domNode.type === 'tag') {
        const Component: React.ElementType = domNode.name as React.ElementType;

        // => TEAM MEMBERS
        if (
          domNode.name === 'div' &&
          (domNode.attribs.class === 'wp-block-essential-blocks-team-member' ||
            domNode.attribs.class === 'eb-parent-wrapper' ||
            domNode.attribs.class.includes('eb-parent-wrapper') ||
            domNode.attribs.class === 'eb-team-wrapper' ||
            domNode.attribs.class.includes('eb-team-wrapper') ||
            (domNode.attribs.class === 'texts' &&
              domNode.parent?.type === 'tag' &&
              domNode.parent?.attribs.class === 'contents'))
        ) {
          return <>{domToReact(children, options)}</>;
        }

        if (
          (domNode.name === 'div' && domNode.attribs.class === 'wp-block-group') ||
          domNode.attribs?.class?.includes('wp-block-group')
        ) {
          return (
            <div className="not-prose grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {domToReact(children, options)}
            </div>
          );
        }

        if (domNode.name === 'div' && domNode.attribs.class === 'eb-team-inner') {
          return (
            <Card className="flex flex-col items-center bg-white text-center shadow-xl">
              {domToReact(children, options)}
            </Card>
          );
        }

        if (
          domNode.name === 'div' &&
          domNode.attribs.class === 'image' &&
          domNode.parent?.type === 'tag' &&
          domNode.parent?.attribs.class === 'eb-team-inner'
        ) {
          return <Avatar className="mt-10 h-48 w-48">{domToReact(children, options)}</Avatar>;
        }

        if (domNode.name === 'img' && domNode.attribs.class === 'avatar') {
          return (
            <>
              <AvatarImage src={domNode.attribs.src} alt={domNode.attribs.alt} />
              <AvatarFallback className="flex-col text-gray-400">
                <UserRoundIcon className="h-28 w-28" />
                <Trans i18nKey="notApplicable">N/A</Trans>
              </AvatarFallback>
            </>
          );
        }

        if (
          domNode.name === 'div' &&
          domNode.attribs.class === 'contents' &&
          domNode.parent?.type === 'tag' &&
          domNode.parent?.attribs.class === 'eb-team-inner'
        ) {
          return <CardHeader>{domToReact(children, options)}</CardHeader>;
        }

        if (
          domNode.name === 'h3' &&
          domNode.attribs.class === 'name' &&
          domNode.parent?.type === 'tag' &&
          domNode.parent?.attribs.class === 'texts'
        ) {
          return <CardTitle>{domToReact(children, options)}</CardTitle>;
        }

        if (
          domNode.name === 'h4' &&
          domNode.attribs.class === 'job_title' &&
          domNode.parent?.type === 'tag' &&
          domNode.parent?.attribs.class === 'texts'
        ) {
          return (
            <CardDescription className="text-base font-semibold">
              {domToReact(children, options)}
            </CardDescription>
          );
        }

        if (
          domNode.name === 'p' &&
          domNode.attribs.class === 'description' &&
          domNode.parent?.type === 'tag' &&
          domNode.parent?.attribs.class === 'texts'
        ) {
          return <CardContent className="p-0 pt-4">{domToReact(children, options)}</CardContent>;
        }

        // => TEAM MEMBERS

        // => ACCORDION
        if (domNode.name === 'div' && domNode.attribs.class === 'lightweight-accordion') {
          return <>{domToReact(children, options)}</>;
        }

        if (
          domNode.name === 'span' &&
          domNode.parent?.type === 'tag' &&
          domNode.parent?.attribs.class === 'lightweight-accordion-title'
        ) {
          return <>{domToReact(children, options)}</>;
        }

        if (
          domNode.name === 'details' &&
          domNode.parent?.type === 'tag' &&
          domNode.parent?.attribs.class === 'lightweight-accordion'
        ) {
          return (
            <Accordion type="multiple">
              <AccordionItem
                value={nodeId}
                className="group mb-2 border border-gray-300 bg-white prose-h3:m-0 prose-p:md:text-lg"
              >
                {domToReact(children, options)}
              </AccordionItem>
            </Accordion>
          );
        }

        if (domNode.name === 'summary' && domNode.attribs.class === 'lightweight-accordion-title') {
          return (
            <AccordionTrigger className="border-l-4 border-transparent p-4 text-xl font-medium hover:no-underline group-hover:border-accent group-hover:bg-secondary">
              <span>{domToReact(children, options)}</span>
            </AccordionTrigger>
          );
        }

        if (domNode.name === 'div' && domNode.attribs.class === 'lightweight-accordion-body') {
          return (
            <AccordionContent className="border-l-4 border-transparent px-4 text-base group-hover:border-accent group-hover:bg-secondary prose-p:mt-0">
              {domToReact(children, options)}
            </AccordionContent>
          );
        }
        // => ACCORDION

        // => TABLE
        if (domNode.name === 'table') {
          return <Table>{domToReact(children, options)}</Table>;
        }

        if (domNode.name === 'thead') {
          return <TableHeader>{domToReact(children, options)}</TableHeader>;
        }

        if (domNode.name === 'tbody') {
          return <TableBody>{domToReact(children, options)}</TableBody>;
        }

        if (domNode.name === 'tfoot') {
          return <TableFooter>{domToReact(children, options)}</TableFooter>;
        }

        if (domNode.name === 'tr') {
          return <TableRow>{domToReact(children, options)}</TableRow>;
        }

        if (domNode.name === 'th') {
          return <TableHead>{domToReact(children, options)}</TableHead>;
        }

        if (domNode.name === 'td') {
          return <TableCell>{domToReact(children, options)}</TableCell>;
        }
        // => TABLE

        if (domNode.name === 'br') {
          return <br />;
        }

        if (domNode.name === 'blockquote') {
          return <blockquote>{domToReact(children, options)}</blockquote>;
        }

        if (domNode.name === 'img') {
          const props = attributesToProps(domNode.attribs);
          return <img alt={domNode.attribs.alt} src={domNode.attribs.src} {...props} />;
        }

        if (domNode.name === 'a') {
          const domain = 'content.starrt.ca';
          const link = domNode.attribs.href;
          const url = new URL(link, `https://${domain}`);
          const isSTARRT = url.hostname.includes('starrt');
          const isExternal = url.protocol.includes('http');
          const isUpload = url.pathname.includes('/wp-content/uploads/');

          if ((isExternal && !isSTARRT) || isUpload) {
            return <ExternalLink href={link}>{domToReact(children, options)}</ExternalLink>;
          }

          return (
            <Link
              to={url.pathname}
              className="link-2 break-words text-primary underline hover:text-primary-dark hover:no-underline"
            >
              {domToReact(children, options)}
            </Link>
          );
        }

        const props = attributesToProps(domNode.attribs);
        return <Component {...props}>{domToReact(children, options)}</Component>;
      }

      return <>{domToReact(children, options)}</>;
    },
  };

  return parse(html, options);
};
